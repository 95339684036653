import React from 'react';
import { Accordion, RawHtml, AccordionItem, CallToAction } from '$shared/components';
import { M120AccordionListModule } from '~/lib/data-contract';
import { ModuleContainer } from '../ModuleContainer';
import { weakKey } from '~/shared/utils/jsx';
import {
    StyledAccordionList,
    StyledHeaderWrapper,
    StyledAccordionWrapper,
    StyledActionWrapper,
} from '$templates/blocks/components/M120Accordion/styled';
import { withErrorBoundary } from '~/shared/utils/errorBoundary';
import { Headline } from '$templates/blocks/components/Headline';

type M120Props = M120AccordionListModule;

export const M120Accordion = ({
    headline,
    accordionItems = [],
    pageElementIndex,
    ...rest
}: M120Props) => {
    return (
        <ModuleContainer hasGutter pageElementIndex={pageElementIndex} {...rest}>
            <StyledAccordionList>
                <StyledHeaderWrapper>
                    {headline && <Headline variant="display3">{headline}</Headline>}
                </StyledHeaderWrapper>
                <StyledAccordionWrapper>
                    <Accordion>
                        {accordionItems?.map((item) => (
                            <AccordionItem header={item.headline} key={weakKey(item)}>
                                <RawHtml html={item.description} />
                                {item.callToAction ? (
                                    <StyledActionWrapper>
                                        <CallToAction callToAction={item.callToAction} />
                                    </StyledActionWrapper>
                                ) : null}
                            </AccordionItem>
                        ))}
                    </Accordion>
                </StyledAccordionWrapper>
            </StyledAccordionList>
        </ModuleContainer>
    );
};

export default withErrorBoundary(M120Accordion);
